import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import "../Css/AdDetail.css";
import MyGallery from './MyGallery';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart, faShareSquare } from '@fortawesome/free-solid-svg-icons';
import { faPhone, faEnvelope, faComment } from '@fortawesome/free-solid-svg-icons';

function AdDetail() {
  const { id } = useParams();
  const [adDetails, setAdDetails] = useState(null);
  const [adImages, setAdImages] = useState([]);
   // State for the hover effect
   const [hoverIndex, setHoverIndex] = useState(null);

    // Dummy action handlers
  const handleWhatsApp = () => {/* Implement WhatsApp action */}
  const handleCall = () => {/* Implement Call action */}
  const handleEmail = () => {/* Implement Email action */}

// Use the environment variable for the API URL
const apiUrl = process.env.REACT_APP_API_URL;
// Assume the first image is the main one and the rest are thumbnails
 

const imagesForGallery = adImages.map((image) => ({
    imageurl: image.imageurl // make sure the property names match what you receive from the API
  }));

  // Fetch advertisement details
  useEffect(() => {
    axios.get(`${apiUrl}/${id}`)
      .then(response => {
        setAdDetails(response.data);
      })
      .catch(error => console.error(`Error fetching ad details: ${error}`));
  }, [id]);

  // Fetch advertisement images
  useEffect(() => {
    axios.get(`${apiUrl}/api/advertisement-images/${id}`)
      .then(response => {
        setAdImages(response.data);
      })
      .catch(error => console.error(`Error fetching ad images: ${error}`));
  }, [id]);

  if (!adDetails) return <div>Loading...</div>;

  const renderItem = (item) => (
    <div className='image-gallery-image' style={{ textAlign: 'center' }}>
      <img
        src={item.original}
        alt={item.originalAlt}
        style={{
          maxHeight: '70vh', // This restricts the height of the image
          maxWidth: '100%', // This restricts the width of the image
          objectFit: 'contain' // This ensures the image aspect ratio is preserved
        }}
      />
      {item.description && <span className='image-gallery-description'>
        {item.description}
      </span>}
    </div>
  );
  const style = {
    container: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '20px',
      borderBottom: '1px solid #ddd',
    },
    button: {
      padding: '10px 20px',
      backgroundColor: 'white',
      border: '0.5px solid gray',
      borderRadius: '5px',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      fontSize: '16px',
      transition: 'background-color 0.3s', // Smooth transition for the hover effect
    },
    buttonHover: {
      backgroundColor: '#f8f9fa', // Gray background on hover, light gray as an example
    },
    icon: {
      marginRight: '5px', // Space between icon and text
    },
    title: {
        fontSize: '24px', // Increased font size
        fontWeight: 'bold', // Make it bold
        color: 'black', // Explicit text color if needed
      }
  };

  const cardstyle = {
    card: {
      borderRadius: '15px',
      padding: '20px',
      boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
      textAlign: 'center',
      backgroundColor: 'white',
    },
    image: {
      maxWidth: '100%',
      borderRadius: '15px',
      margin: '0 0 20px',
    },
    title: {
      fontWeight: 'bold',
      marginBottom: '20px',
    },
    button: {
      margin: '5px',
      padding: '10px 20px',
      border: 'none',
      borderRadius: '5px',
      cursor: 'pointer',
    },
    whatsappButton: {
      backgroundColor: '#25d366', // WhatsApp color
      color: 'white',
    },
    callButton: {
      backgroundColor: '#ff5e57', // Red color for call button
      color: 'white',
    },
    emailButton: {
      backgroundColor: '#3498db', // Blue color for email button
      color: 'white',
    },
    icon: {
      marginRight: '8px',
    },
  };

  return (
     
 
    
    <div className="ad-content">
    
        {/* استخدام MyGallery هنا لعرض معرض الصور */}
      <MyGallery images={imagesForGallery} />
      
      
      <div style={style.container}>
      <span style={style.title}>{adDetails.title} </span>
    
      <div>
        <button
          style={hoverIndex === 1 ? { ...style.button, ...style.buttonHover } : style.button}
          onMouseEnter={() => setHoverIndex(1)}
          onMouseLeave={() => setHoverIndex(null)}
        >
          <FontAwesomeIcon icon={faHeart} style={style.icon} />
          أضف إلى مفضلتي
        </button>
        <button
          style={hoverIndex === 2 ? { ...style.button, ...style.buttonHover } : style.button}
          onMouseEnter={() => setHoverIndex(2)}
          onMouseLeave={() => setHoverIndex(null)}
        >
          <FontAwesomeIcon icon={faShareSquare} style={style.icon} />
          مشاركة
        </button>
      </div>
      <div style={cardstyle.card}>
      اتصل بالمعلن
      <div style={cardstyle.title}>{adDetails.user_name}</div>
      <div>
        <button style={{ ...cardstyle.button, ...cardstyle.whatsappButton }} onClick={handleWhatsApp}>
          <FontAwesomeIcon icon={faComment} cardstyle={style.icon} />
          واتس
        </button>
        <button style={{ ...cardstyle.button, ...cardstyle.callButton }} onClick={handleCall}>
          <FontAwesomeIcon icon={faPhone} cardstyle={style.icon} />
          اتصال
        </button>
        <button style={{ ...cardstyle.button, ...cardstyle.emailButton }} onClick={handleEmail}>
          <FontAwesomeIcon icon={faEnvelope} style={cardstyle.icon} />
          ايميل
        </button>
      </div>
      
    </div>
    
    </div>
   
     
     
    
    </div>
   
   
      
 
  );
}

export default AdDetail;
