import React, { useEffect, useState } from "react";
import axios from "axios";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "../Css/AdsSlider.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarkerAlt,
  faCalendarAlt,
  faBars,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from 'react-router-dom';

function AdsSlider() {
  const [ads, setAds] = useState([]);
  
  // Use the environment variable for the API URL
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    axios
      .get(`${apiUrl}/api/advertisements`)
      .then((response) => {
        setAds(response.data);
      })
      .catch((error) =>
        console.error("There was an error fetching the ads:", error)
      );
  }, [apiUrl]);

  return (
    <div className="">
      <div className="mb-5">
        <div className=" ">
          <a href="#" className="no-underline">
            <h5>استمر في التصفح</h5>
          </a>
          <div className="end">
            <div className="darkGrayColor mt-8 font-20 start">
              اخر الاعلانات
            </div>
          </div>
        </div>
        <a href="#" className="no-underline">
          المزيد
          <svg
            width="21"
            height="21"
            className="inline vMiddle ms-8"
            viewBox="0 0 21 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            data-name="IconLeftwardsArrow"
            data-flip="true"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M5.70863 12.5476L11.8133 18.7525L9.60724 20.9947L1.57134 13.1448C0.366896 11.9683 0.366395 10.0311 1.57023 8.85391L9.60725 0.994758L11.8133 3.23701L5.72755 9.42264L20.9906 9.42264L20.9906 12.5476L5.70863 12.5476Z"
              fill="#0179FF"
            ></path>
          </svg>
        </a>
      </div>

      <Swiper
        slidesPerView={1} // Default number of slides per view
        spaceBetween={10} // Space between slides
        breakpoints={{
          // When window width is >= 768px
          768: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          // When window width is >= 1024px
          1024: {
            slidesPerView: 5,
            spaceBetween: 30,
          },
        }}
      >
        {ads.map((ad, index) => (
          <SwiperSlide key={index}>
            <Link to={`/ad/${ad.ad_id}`} className="ad-link"> 
            <div className="ad-card">
              <div className="row no-gutters">
                {/* Ad Image */}
                <div className="col-12">
                  <img
                    src={`${apiUrl}/uploads/advimages/${ad.imageurl}`}
                    alt="Ad"
                    className="ad-image w-100"
                  />
                </div>

                {/* Category Name */}
                <div className="col-12 text-right category">
                  <p className="mt-2 ">
                    <FontAwesomeIcon icon={faBars} /> {ad.cat_name}
                  </p>
                </div>

                {/* Ad Title and User Avatar */}
                <div className="col-12">
                  <div className="card-header d-flex  ">
                    <h5>{ad.title}</h5>
                  </div>
                </div>

                {/* Ad Footer */}
                <div className="col-12">
                  <div className="ad-footer d-flex justify-content-between align-items-center">
                    <div className="navigation-icon">
                      <FontAwesomeIcon
                        icon={faCalendarAlt}
                        className="my-icon-color"
                      />
                    </div>
                    <p>{new Date(ad.updated_at).toLocaleDateString()}</p>
                    <div className="navigation-icon">
                      <FontAwesomeIcon
                        icon={faMapMarkerAlt}
                        className="my-icon-color"
                      />
                    </div>
                    <div className="location">
                      <p>{ad.ad_location}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </Link>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}

export default AdsSlider;
