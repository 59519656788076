import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../Css/LoginRegisterModal.css";
import {
  faFacebookF,
  faGoogle,
  faApple,
} from "@fortawesome/free-brands-svg-icons";
const LoginRegisterModal = ({ isOpen, onClose }) => {
  const [isLoginView, setIsLoginView] = useState(true);

  const [formData, setFormData] = useState({
    id: '',
    fname_ar: '',
    qname: '',
    dob: '', // Date of birth
    age: '',
    sex: '',
    address: '',
    phone: '',
    username: '',
    passwordinput: '',
    email: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Submit logic here. Example:
    console.log(formData);
    // You might want to send 'formData' to your server here.
  };
  return (
    <Modal show={isOpen} onHide={onClose} centered className="login-modal">
      <Modal.Header closeButton>
        <Modal.Title>{isLoginView ? "سجل الدخول" : "تسجيل حساب"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isLoginView ? (
          <>
            {/* Login form fields here */}
            <Form className="modal-body">
        <Form.Group controlId="loginUsername">
          <Form.Label>اسم المستخدم</Form.Label>
          <Form.Control type="text" placeholder="Enter username" />
        </Form.Group>

        <Form.Group controlId="loginPassword">
          <Form.Label>كلمة المرور</Form.Label>
          <Form.Control type="password" placeholder="Password" />
        </Form.Group>

        <Button variant="primary mt-3 " type="submit" block>
          تسجيل الدخول
        </Button>
      </Form>
            <Button className="social-btn facebook" block>
              <FontAwesomeIcon icon={faFacebookF} className="social-icon" />
              الدخول بحساب فيسبوك
            </Button>
            <Button className="social-btn google" block>
              <FontAwesomeIcon icon={faGoogle} className="social-icon" />
              الدخول بحساب جوجل
            </Button>
            <Button className="social-btn apple" block>
              <FontAwesomeIcon icon={faApple} className="social-icon" />
              الدخول بحساب أبل
            </Button>
            {/* ...other login options */}
            <Button
              variant="link"
              className="switch-btn"
              onClick={() => setIsLoginView(false)}
            >
               ليس لديك حساب علي تجوالي ؟ سجل حساب جديد من هنا
            </Button>
          </>
        ) : (
          <>
            {/* Register form fields here */}
            {/* ...other registration options */}
            <form onSubmit={handleSubmit}>
      <div className="form-body">
        {/* Name Field */}
        <div className="form-group row mb-3">
          <label className="control-label col-md-3">الاسم</label>
          <div className="col-md-9">
            <input
              name="fname_ar"
              value={formData.fname_ar}
              onChange={handleChange}
              className="form-control"
              type="text"
            />
          </div>
        </div>
        {/* Tribe Name Field */}
        <div className="form-group row mb-3">
          <label className="control-label col-md-3">اسم القبيلة</label>
          <div className="col-md-9">
            <input
              name="qname"
              value={formData.qname}
              onChange={handleChange}
              className="form-control"
              type="text"
            />
          </div>
        </div>
        {/* Date of Birth Field */}
<div className="form-group row mb-3">
  <label className="control-label col-md-3">تاريخ الميلاد</label>
  <div className="col-md-9">
    <input
      name="dob"
      value={formData.dob}
      onChange={handleChange}
      className="form-control"
      type="date"
    />
  </div>
</div>

        {/* Age Field */}
        <div className="form-group row mb-3">
          <label className="control-label col-md-3">العمر</label>
          <div className="col-md-9">
            <input
              name="age"
              value={formData.age}
              onChange={handleChange}
              className="form-control"
              type="text"
            />
          </div>
        </div>
        {/* Gender Select */}
        <div className="form-group row mb-3">
          <label className="control-label col-md-3">الجنس</label>
          <div className="col-md-9">
            <select
              name="sex"
              value={formData.sex}
              onChange={handleChange}
              className="form-control"
            >
              <option value="">--حدد--</option>
              <option value="male">ذكر</option>
              <option value="female">انثى</option>
            </select>
          </div>
        </div>
        {/* Address Field */}
        <div className="form-group row mb-3">
          <label className="control-label col-md-3">Address</label>
          <div className="col-md-9">
            <textarea
              name="address"
              value={formData.address}
              onChange={handleChange}
              className="form-control"
            />
          </div>
        </div>
        {/* Phone Number Field */}
        <div className="form-group row mb-3">
          <label className="control-label col-md-3">رقم الجوال</label>
          <div className="col-md-9">
            <input
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              className="form-control"
              type="text"
            />
          </div>
        </div>
        {/* Username Field */}
        <div className="form-group row mb-3">
          <label className="control-label col-md-3">اسم المستخدم</label>
          <div className="col-md-9">
            <input
              name="username"
              value={formData.username}
              onChange={handleChange}
              className="form-control"
              type="text"
            />
          </div>
        </div>
        {/* Password Field */}
        <div className="form-group row mb-3">
          <label className="control-label col-md-3">كلمة المرور</label>
          <div className="col-md-9">
            <input
              name="passwordinput"
              value={formData.passwordinput}
              onChange={handleChange}
              className="form-control"
              type="password"
            />
          </div>
        </div>
        {/* Email Field */}
        <div className="form-group row mb-3">
          <label className="control-label col-md-3">البريد الألكترونى</label>
          <div className="col-md-9">
            <input
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="form-control"
              type="text"
            />
          </div>
        </div>
        {/* Submit Button */}
        <div className="form-group row mb-3">
          <div className="col-md-9 offset-md-3">
            <button type="submit" className="btn btn-primary">ارسال</button>
          </div>
        </div>
      </div>
    </form>
            <Button
              variant="link"
              className="switch-btn"
              onClick={() => setIsLoginView(true)}
            >
              لديك حساب علي تجوالي ؟ سجل الدخول من هنا
            </Button>
          </>
        )}
        
      </Modal.Body>
      <Modal.Footer>
        <div className="modal-footer-content">
          عند انضمامك لنا على تجوالي فإنك توافق ضمنياً على <a href="/terms">شروط وأحكام الخدمة</a> إضافة إلى <a href="/privacy">سياسة الخصوصية</a>.
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default LoginRegisterModal;
