import React from 'react';

const ContactInfo = () => {
  return (
    <div className="row">
      <div className="right-div row col-12 col-md-10">
        <div className="content col-12 col-md-4 col-lg-3 d-flex align-items-center ms-2">
          <div className="icon-img">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="#999" xmlns="http://www.w3.org/2000/svg">
              <path d="M20 4H4C2.9 4 2.01 4.9 2.01 6L2 18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6C22 4.9 21.1 4 20 4ZM20 8L12 13L4 8V6L12 11L20 6V8Z" fill="#999"></path>
            </svg>
          </div>
          <div className="text">
            <h5>البريد الالكتروني</h5>
            <p><a href="mailto:eg@tejwaly.com">eg@tejwaly.com</a></p>
          </div>
        </div>

        <div className="content col-12 col-md-4 col-lg-2 d-flex align-items-center ms-2">
          <div className="icon-img">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="#0179FF" xmlns="http://www.w3.org/2000/svg">
              <path d="M19.23 15.26L16.69 14.97C16.08 14.9 15.48 15.11 15.05 15.54L13.21 17.38C10.38 15.94 8.06004 13.63 6.62004 10.79L8.47004 8.94001C8.90004 8.51001 9.11004 7.91001 9.04004 7.30001L8.75004 4.78001C8.63004 3.77001 7.78004 3.01001 6.76004 3.01001H5.03004C3.90004 3.01001 2.96004 3.95001 3.03004 5.08001C3.56004 13.62 10.39 20.44 18.92 20.97C20.05 21.04 20.99 20.1 20.99 18.97V17.24C21 16.23 20.24 15.38 19.23 15.26V15.26Z" fill="#0179FF"></path>
            </svg>
          </div>
          <div className="text">
            <h5>الهاتف</h5>
            <p><a href="tel:+123456789">إتصل بنا</a></p>
          </div>
        </div>

        <div className="content col-12 col-md-4 col-lg-2 d-flex align-items-center ms-2">
          <div className="icon-img">
            <svg width="20" height="32" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" data-name="IconWhatsapp">
              <path d="M19.05 4.91C17.18 3.03 14.69 2 12.04 2C6.58005 2 2.13005 6.45 2.13005 11.91C2.13005 13.66 2.59005 15.36 3.45005 16.86L2.05005 22L7.30005 20.62C8.75005 21.41 10.38 21.83 12.04 21.83C17.5 21.83 21.9501 17.38 21.9501 11.92C21.9501 9.27 20.92 6.78 19.05 4.91ZM12.04 20.15C10.56 20.15 9.11005 19.75 7.84005 19L7.54005 18.82L4.42005 19.64L5.25005 16.6L5.05005 16.29C4.23005 14.98 3.79005 13.46 3.79005 11.91C3.79005 7.37 7.49005 3.67 12.03 3.67C14.23 3.67 16.3 4.53 17.85 6.09C19.41 7.65 20.26 9.72 20.26 11.92C20.2801 16.46 16.58 20.15 12.04 20.15ZM16.56 13.99C16.31 13.87 15.09 13.27 14.87 13.18C14.64 13.1 14.48 13.06 14.31 13.3C14.14 13.55 13.67 14.11 13.53 14.27C13.39 14.44 13.24 14.46 12.99 14.33C12.74 14.21 11.94 13.94 11 13.1C10.26 12.44 9.77005 11.63 9.62005 11.38C9.48005 11.13 9.60005 11 9.73005 10.87C9.84005 10.76 9.98005 10.58 10.1 10.44C10.22 10.3 10.27 10.19 10.35 10.03C10.43 9.86 10.39 9.72 10.33 9.6C10.27 9.48 9.77005 8.26 9.57005 7.76C9.37005 7.28 9.16005 7.34 9.01005 7.33C8.86005 7.33 8.70005 7.33 8.53005 7.33C8.36005 7.33 8.10005 7.39 7.87005 7.64C7.65005 7.89 7.01005 8.49 7.01005 9.71C7.01005 10.93 7.90005 12.11 8.02005 12.27C8.14005 12.44 9.77005 14.94 12.25 16.01C12.84 16.27 13.3 16.42 13.66 16.53C14.25 16.72 14.79 16.69 15.22 16.63C15.7 16.56 16.69 16.03 16.89 15.45C17.1 14.87 17.1 14.38 17.03 14.27C16.96 14.16 16.81 14.11 16.56 13.99Z" fill="#25D366"></path>
            </svg>
          </div>
          <div className="text">
            <h5>واتساب</h5>
            <p><a href="https://wa.me/yourNumber">تواصل معنا</a></p>
          </div>
        </div>

        <div className="content col-12 col-md-4 col-lg-3 d-flex align-items-center ms-2">
          <div className="icon-img">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="#0179FF" xmlns="http://www.w3.org/2000/svg">
              <path d="M12.0001 2.03467C6.49141 2.03467 2.00586 6.50729 2.00586 12.0001C2.00586 14.5015 2.93071 16.7839 4.46251 18.5361C5.57811 19.8156 7.01742 20.807 8.64748 21.3891C9.69372 21.7638 10.8209 21.9655 11.9943 21.9655C13.1677 21.9655 14.2949 21.7638 15.3411 21.3891C16.9712 20.807 18.4105 19.8156 19.5261 18.5361C21.0579 16.7839 21.9828 14.4958 21.9828 12.0001C21.9943 6.50729 17.5087 2.03467 12.0001 2.03467ZM19.2602 17.222C19.081 16.8012 18.7515 16.4727 18.2949 16.3056L14.4163 14.8012L12.6706 18.6226L12.5261 16.4381L12.9192 16.0001L12.6706 15.1586C12.2891 15.245 11.7053 15.245 11.3238 15.1586L11.0752 16.0001L11.4683 16.4381L11.3238 18.6226L9.57811 14.8012L5.6995 16.3056C5.24285 16.4785 4.91337 16.8012 4.73418 17.222C3.6706 15.7522 3.04054 13.9482 3.04054 12.0001C3.04054 7.0779 7.05788 3.07213 11.9943 3.07213C16.9307 3.07213 20.9481 7.0779 20.9481 12.0001C20.9538 13.9482 20.3238 15.7522 19.2602 17.222Z" fill="#0179FF"></path>
              <path d="M15.5955 9.1066C15.8324 8.14407 15.8093 7.53888 15.3874 6.61093C15.1099 5.97692 14.4394 5.38326 13.5723 5.11813C12.3989 4.75502 10.9076 4.89911 9.86135 5.53312C8.84979 5.36021 8.01164 7.26799 8.22551 8.4726L8.37002 9.30257L8.16771 9.45242C8.16193 10.3861 8.26597 10.9452 8.59545 11.8213L8.85557 11.9596C9.17927 13.049 10.1619 14.1786 11.2139 14.6109C11.7689 14.7031 12.2429 14.7031 12.7978 14.6109C13.8498 14.1786 14.8324 13.0432 15.1561 11.9596L15.4163 11.8213C15.7457 10.9452 15.8498 10.3861 15.844 9.45242L15.5723 9.2507L15.5955 9.1066Z" fill="#0179FF"></path>
            </svg>
          </div>
          <div className="text">
            <h5>تواصل مع المندوبين</h5>
            <p><a href="https://example.com/sales">tejwaly/sales</a></p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactInfo;
