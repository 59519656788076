import React from 'react';
import '../Css/SearchComponent.css'; // Assuming you have some custom styles

const SearchComponent = () => {
  return (
    <div className="container my-3">
      <div className="d-flex align-items-center justify-content-between flex-wrap">
        
        <div className="d-flex align-items-center flex-grow-1">
          <div className="input-group">
            <span className="input-group-text">
              <i className="bi bi-geo-alt"></i>
            </span>
            <input type="text" className="form-control" placeholder="أدخل كلمة البحث" />
          </div>
        </div>

        <div className="d-flex">
          <div className="me-2">
            <div className="dropdown">
              <button className="btn btn-outline-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                التصنيف
              </button>
              <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                <li><a className="dropdown-item" href="#">جديد</a></li>
                <li><a className="dropdown-item" href="#">مستعمل</a></li>
              </ul>
            </div>
          </div>

          <div className="me-2">
            <div className="dropdown">
              <button className="btn btn-outline-secondary dropdown-toggle" type="button" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false">
                الحالة
              </button>
              <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton2">
                <li><a className="dropdown-item" href="#">جديد</a></li>
                <li><a className="dropdown-item" href="#">مستعمل</a></li>
              </ul>
            </div>
          </div>

          <div className="me-2">
            <div className="dropdown">
              <button className="btn btn-outline-secondary dropdown-toggle" type="button" id="dropdownMenuButton3" data-bs-toggle="dropdown" aria-expanded="false">
                السعر (درهم)
              </button>
              <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton3">
                <li><a className="dropdown-item" href="#">Option 1</a></li>
                <li><a className="dropdown-item" href="#">Option 2</a></li>
              </ul>
            </div>
          </div>

          <div className="me-2">
            <button className="btn btn-link">مسح عوامل التصفية</button>
          </div>

          <div className="me-2">
            <button className="btn btn-link">حفظ البحث</button>
          </div>
        </div>
        
      </div>
    </div>
  );
};

export default SearchComponent;
