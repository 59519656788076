import React from 'react';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';

function MyGallery({ images }) {
  // Your image array should be an array of objects with 'original' and 'thumbnail' keys
  const imageArray = images.map((img) => ({
    original: `${process.env.REACT_APP_API_BASE_URL}/uploads/advimages/${img.imageurl}`,
    thumbnail: `${process.env.REACT_APP_API_BASE_URL}/uploads/advimages/${img.imageurl}`,
  }));

  return <ImageGallery items={imageArray} />;
}

export default MyGallery;
