import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faCity, faUmbrellaBeach, faHeart, faTrophy, faStar, faTags, faUser, faCog, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import LoginRegisterModal from '../Componnets/LoginRegister/LoginRegisterModal';
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom'; // Import Link for routing

const public_url = process.env.PUBLIC_URL;
const imageUrl = public_url + "/assest/images/";
const visitorCountryCode = "EG";
const isLoggedIn = false;

function Header() {
  const [isLoginView, setIsLoginView] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  useEffect(() => {
    console.log("Modal state changed", isModalOpen);
  }, [isModalOpen]);

  return (
    <header>
      <Navbar expand="lg" className="header">
        <Container>
          <Navbar.Brand as={Link} to="/">
            <img src={`${imageUrl}Tejwaly_Logo_02.png`} alt="Tejwaly Logo" className="header-logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="main-navbar-nav" />
          <Navbar.Collapse id="main-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link as={Link} to="/" className="nav-item">
                <FontAwesomeIcon icon={faHome} />
                <span>الرئيسية</span>
              </Nav.Link>
              <Nav.Link as={Link} to="/touristic_facilities" className="nav-item">
                <FontAwesomeIcon icon={faCity} />
                <span>منشات سياحية</span>
              </Nav.Link>
              <Nav.Link as={Link} to="/touristic_places" className="nav-item">
                <FontAwesomeIcon icon={faUmbrellaBeach} />
                <span>اماكن سياحية</span>
              </Nav.Link>
              <Nav.Link as={Link} to="/ads" className="nav-item">
                <FontAwesomeIcon icon={faHeart} />
                <span>أعلانات</span>
              </Nav.Link>
              <Nav.Link as={Link} to="/photos" className="nav-item">
                <FontAwesomeIcon icon={faTrophy} />
                <span>صور</span>
              </Nav.Link>
              <Nav.Link as={Link} to="/sports" className="nav-item">
                <FontAwesomeIcon icon={faStar} />
                <span>رياضة</span>
              </Nav.Link>
              <Nav.Link as={Link} to="/offers_discounts" className="nav-item">
                <FontAwesomeIcon icon={faTags} />
                <span>تخفيضات</span>
              </Nav.Link>
            </Nav>
            {isLoggedIn ? (
              <Dropdown align="end">
                <Dropdown.Toggle variant="secondary" id="dropdown-basic" className="register-button">
                  حسابي
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item as={Link} to="/profile">
                    <FontAwesomeIcon icon={faUser} className="me-2" />الملف الشخصي
                  </Dropdown.Item>
                  <Dropdown.Item as={Link} to="/favorites">
                    <FontAwesomeIcon icon={faHeart} className="me-2" />المفضلة
                  </Dropdown.Item>
                  <Dropdown.Item as={Link} to="/settings">
                    <FontAwesomeIcon icon={faCog} className="me-2" />الإعدادات
                  </Dropdown.Item>
                  <Dropdown.Item as={Link} to="/logout">
                    <FontAwesomeIcon icon={faSignOutAlt} className="me-2" />تسجيل الخروج
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            ) : (
              <div className="login-prompt">
                <button onClick={openModal} className="register-button">سجل الدخول</button>
                <button className="register-button" onClick={() => setIsLoginView(false)}>أضف إعلانك</button>
                <LoginRegisterModal isOpen={isModalOpen} onClose={closeModal} />
              </div>
            )}
          </Navbar.Collapse>
        </Container>
      </Navbar>

      {/* Secondary Navigation Menu */}

    </header>
  );
}

export default Header;
