// src/pages/Ads.js
import React from 'react';
import AdvCatComponent from '../Componnets/AdvCatComponent';
import AdsSlider from '../Componnets/AdsSlider';
import { Container } from 'reactstrap';
function Ads() {
  return (
    <div className="app-container">
       <Container>
      <AdvCatComponent />
      <hr className="header-divider" />
      <AdsSlider />
      </Container>
    </div>
  );
}

export default Ads;
