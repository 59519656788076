// src/pages/OffersAndDiscounts.js
import React from 'react';

function OffersAndDiscounts() {
  return (
    <div>
      <h2>عروض وتخفيضات</h2>
      <p>Find amazing offers and discounts. Don't miss out on great deals available just for you.</p>
    </div>
  );
}

export default OffersAndDiscounts;
