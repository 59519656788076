// src/pages/Sports.js
import React from 'react';

function Sports() {
  return (
    <div>
      <h2>رياضة</h2>
      <p>Stay updated with the latest sports news and events. Follow your favorite teams and athletes.</p>
    </div>
  );
}

export default Sports;
