// src/pages/TouristPlaces.js
import React from 'react';

function TouristPlaces() {
  return (
    <div>
      <h2>اماكن سياحية</h2>
      <p>Discover beautiful tourist places to visit. Find detailed information on popular destinations.</p>
    </div>
  );
}

export default TouristPlaces;
