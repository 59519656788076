import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'; 
import AdvCatComponent from './Componnets/AdvCatComponent';
import CategoryItems from './Componnets/CategoryItems';
import AdDetail from './Componnets/AdDetail';
import Header from './Templates/Header';
import Footer from './Templates/Footer';
import Nav from 'react-bootstrap/Nav';
import { Link } from 'react-router-dom'; // Import Link for routing
import AdsSlider from './Componnets/AdsSlider';
import { Container } from 'reactstrap';
import SearchHeader from './Componnets/SearchHeader';
import 'bootstrap/dist/css/bootstrap.rtl.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import './App.css';
import './Css/Footer.css';
import './Css/Header.css';
import './Css/SearchHeader.css';
import TouristicFacilities from './Pages/TouristicFacilities';
import TouristPlaces from './Pages/TouristPlaces';
import Ads from './Pages/Ads';
import Photos from './Pages/Photos';
import Sports from './Pages/Sports';
import OffersAndDiscounts from './Pages/OffersAndDiscounts';
import PrivacyPolicy from './Pages/PrivacyPolicy';
function App() {
  useEffect(() => {
    document.title = "Tejwaly تجوالي";
  }, []);

  return (
    <Router>
      <div className="App" dir="rtl">
        <Header />
        <div className="app-container"  >
          <Routes>
            {/* Home Page */}
            <Route path="/" element={
              <Container>
        {/*  <nav className="secondary-nav">
        <Nav className="me-auto">
          <Nav.Link as={Link} to="/info" className="secondary-nav-item">معلومات</Nav.Link>
          <Nav.Link as={Link} to="/property_for_sale" className="secondary-nav-item">عقار للبيع</Nav.Link>
          <Nav.Link as={Link} to="/my_store" className="secondary-nav-item">متجري</Nav.Link>
          <Nav.Link as={Link} to="/property_for_rent" className="secondary-nav-item">عقار للإيجار</Nav.Link>
          <Nav.Link as={Link} to="/wallet" className="secondary-nav-item">المحفظة</Nav.Link>
          <Nav.Link as={Link} to="/jobs" className="secondary-nav-item">وظائف شاغرة</Nav.Link>
          <Nav.Link as={Link} to="/vehicles" className="secondary-nav-item">مركبات</Nav.Link>
        </Nav>
      </nav>*/}
                <SearchHeader />
                <AdvCatComponent />
                <hr className="header-divider" />
                <AdsSlider />
              </Container>
            } />
            
            {/* Other Pages */}
            <Route path="/category_items/:id" element={<CategoryItems />} />
            <Route path="/ad/:id" element={<AdDetail />} />
            {/* Add more routes as needed */}
            
          <Route path="/touristic-facilities" element={<TouristicFacilities />} />
          <Route path="/tourist-places" element={<TouristPlaces />} />
          <Route path="/ads" element={<Ads />} />
          <Route path="/photos" element={<Photos />} />
          <Route path="/sports" element={<Sports />} />
          <Route path="/offers-discounts" element={<OffersAndDiscounts />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
