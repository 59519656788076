// src/pages/TouristicFacilities.js
import React from 'react';

function TouristicFacilities() {
  return (
    <div>
      <h2>منشات سياحية</h2>
      <p>This is the Touristic Facilities page.</p>
    </div>
  );
}

export default TouristicFacilities;
