import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // Import useNavigate instead of useHistory
import '../Css/AdvCatComponent.css'; // Ensure the path to your CSS file is correct

const AdvCatComponent = () => {
  const [advCats, setAdvCats] = useState([]);
  const navigate = useNavigate(); // Use the useNavigate hook

  // Use the environment variable for the API URL
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    axios.get(`${apiUrl}/api/adv_cat`)
      .then(response => {
        const updatedData = response.data.map(cat => ({
          ...cat,
          imageUrl: cat.image ? `${apiUrl}/uploads/advcataloge/${cat.image}` : null
        }));
        setAdvCats(updatedData);
      })
      .catch(error => console.error('There was an error fetching the advertisement categories:', error));
  }, [apiUrl]);

  const handleCategoryClick = (catId) => {
    // Navigate to the new route with the category ID
    navigate(`/category_items/${catId}`); // Use the navigate function to navigate
  };

  return (
    <div className="advCatGallery">
      <h1 className='header'>اقسام الاعلانات</h1>
      {advCats.map((advCat) => (
        <div
          key={advCat.cat_id}
          className="advCatItem"
          onClick={() => handleCategoryClick(advCat.cat_id)}
          role="button"
          tabIndex={0}
        >
          <div className="advCatImageWrapper">
            <img src={advCat.imageUrl} alt={advCat.cat_name} className="advCatImage" />
          </div>
          <div className="advCatName">{advCat.cat_name}</div>
        </div>
      ))}
    </div>
  );
};

export default AdvCatComponent;
