import React, { useState } from 'react';


function SearchHeader() {
    const [searchTerm, setSearchTerm] = useState('');

    const menuItems = [

      'المنشأت السياحية',
      'المجتمع',
      'خدمات',
      'موتورات',
      'عقار للبيع',
      'عقار للإيجار',
      'عقار للبدل',
      'مركبات',
      'قوارب',
      'الكل',
      // ... add other menu items as needed
    ];
  
    const handleMenuItemClick = (item) => {
      setSearchTerm(`بحث عن ${item}`);
    };
  
    return (
      <div className="search-component">
        <div className="background-image-overlay"></div>
        <h2 className="textshadow">من خلال تجوالي بإمكانك البحث عن أي شيء</h2>
        <div className="search-area">
          <div className="left-menu">
            {menuItems.map((item) => (
              <button key={item} className="menu-item" onClick={() => handleMenuItemClick(item)}>
                {item}
              </button>
            ))}
          </div>
          <div className="search-bar">
            <input
              type="text"
              placeholder="أبحث عن اي شئ..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <button className="search-button">بحث</button>
          </div>
        </div>
      </div>
    );
  };

export default SearchHeader;
