// src/pages/Photos.js
import React from 'react';

function Photos() {
  return (
    <div>
      <h2>صور</h2>
      <p>Explore our gallery of photos. Capture the essence of various events and locations.</p>
    </div>
  );
}

export default Photos;
