import React from 'react';
import '../Css/privacy-policy.css'; // Import the CSS file
export default function PrivacyPolicy() {
  return (
    <div className="container mx-auto py-6 flex flex-col justify-center relative overflow-hidden sm:py-12">
      <div className="relative px-6 pt-10 pb-8 bg-white shadow-xl ring-1 ring-gray-900/5 sm:max-w-lg sm:mx-auto sm:rounded-lg sm:px-10">
        <div className="max-w-md mx-auto">
          <div className="divide-y divide-gray-300/50">
            <h1 className="text-3xl font-bold text-gray-900">سياسة الخصوصية</h1>
            <div className="py-8 text-base leading-7 space-y-6 text-gray-600">
           
<p>آخر تحديث: 11 أغسطس 2024</p>
<p>تصف سياسة الخصوصية هذه سياساتنا وإجراءاتنا بشأن جمع واستخدام والكشف عن معلوماتك عند استخدامك للخدمة، وتخبرك عن حقوقك في الخصوصية وكيف يحميك القانون.</p>
<p>نستخدم بياناتك الشخصية لتوفير وتحسين الخدمة. باستخدامك للخدمة، فإنك توافق على جمع واستخدام المعلومات وفقًا لسياسة الخصوصية هذه.</p>
<h2>التفسير والتعريفات</h2>
<h3>التفسير</h3>
<p>الكلمات التي تبدأ بحرف كبير لها معانٍ محددة وفقًا للشروط التالية. يجب أن يكون للتعريفات التالية نفس المعنى سواء ظهرت بصيغة المفرد أو الجمع.</p>
<h3>التعريفات</h3>
<p>لأغراض سياسة الخصوصية هذه:</p>
<ul>
<li>
<p><strong>الحساب</strong> يعني حسابًا فريدًا تم إنشاؤه لك للوصول إلى خدمتنا أو أجزاء من خدمتنا.</p>
</li>
<li>
<p><strong>الطرف الثالث</strong> يعني كيانًا يتحكم في الطرف الآخر، أو يخضع لرقابة من قبله أو يكون تحت الرقابة المشتركة مع الطرف الآخر، حيث يعني "التحكم" امتلاك 50% أو أكثر من الأسهم أو حقوق الملكية أو الأوراق المالية الأخرى التي تمنح الحق في التصويت لانتخاب المديرين أو السلطات الإدارية الأخرى.</p>
</li>
<li>
<p><strong>الشركة</strong> (المشار إليها إما بـ "الشركة"، "نحن"، "لنا" أو "خاصتنا" في هذه الاتفاقية) تشير إلى Tejwaly، Musct عمان.</p>
</li>
<li>
<p><strong>الكوكيز</strong> هي ملفات صغيرة يتم وضعها على جهاز الكمبيوتر الخاص بك أو جهازك المحمول أو أي جهاز آخر بواسطة موقع ويب، تحتوي على تفاصيل تاريخ التصفح الخاص بك على ذلك الموقع من بين استخداماتها العديدة.</p>
</li>
<li>
<p><strong>البلد</strong> تشير إلى: عمان</p>
</li>
<li>
<p><strong>الجهاز</strong> يعني أي جهاز يمكنه الوصول إلى الخدمة مثل الكمبيوتر أو الهاتف المحمول أو الجهاز اللوحي الرقمي.</p>
</li>
<li>
<p><strong>البيانات الشخصية</strong> هي أي معلومات تتعلق بشخص محدد أو يمكن التعرف عليه.</p>
</li>
<li>
<p><strong>الخدمة</strong> تشير إلى الموقع الإلكتروني.</p>
</li>
<li>
<p><strong>مزود الخدمة</strong> يعني أي شخص طبيعي أو قانوني يعالج البيانات نيابة عن الشركة. تشير إلى شركات أو أفراد من طرف ثالث يتم توظيفهم من قبل الشركة لتسهيل الخدمة، لتقديم الخدمة نيابة عن الشركة، لأداء الخدمات المتعلقة بالخدمة أو لمساعدة الشركة في تحليل كيفية استخدام الخدمة.</p>
</li>
<li>
<p><strong>خدمة وسائل التواصل الاجتماعي من طرف ثالث</strong> تشير إلى أي موقع ويب أو أي شبكة اجتماعية يمكن من خلالها للمستخدم تسجيل الدخول أو إنشاء حساب لاستخدام الخدمة.</p>
</li>
<li>
<p><strong>بيانات الاستخدام</strong> تشير إلى البيانات التي يتم جمعها تلقائيًا، إما من خلال استخدام الخدمة أو من البنية التحتية للخدمة نفسها (على سبيل المثال، مدة زيارة الصفحة).</p>
</li>
<li>
<p><strong>الموقع الإلكتروني</strong> تشير إلى Tejwaly، المتاحة على <a href="https://portal.tejwaly.com" rel="external nofollow noopener" target="_blank">https://portal.tejwaly.com</a></p>
</li>
<li>
<p><strong>أنت</strong> تعني الشخص الذي يصل إلى الخدمة أو يستخدمها، أو الشركة، أو الكيان القانوني الآخر الذي ينوب عنه هذا الشخص في الوصول إلى الخدمة أو استخدامها، حسب الاقتضاء.</p>
</li>
</ul>
<h2>جمع واستخدام بياناتك الشخصية</h2>
<h3>أنواع البيانات التي يتم جمعها</h3>
<h4>البيانات الشخصية</h4>
<p>أثناء استخدامك لخدمتنا، قد نطلب منك تزويدنا بمعلومات محددة يمكن استخدامها للتواصل معك أو التعرف عليك. قد تشمل المعلومات الشخصية القابلة للتحديد، ولكن لا تقتصر على:</p>
<ul>
<li>
<p>عنوان البريد الإلكتروني</p>
</li>
<li>
<p>الاسم الأول والاسم الأخير</p>
</li>
<li>
<p>رقم الهاتف</p>
</li>
<li>
<p>العنوان، الدولة، المقاطعة، الرمز البريدي، المدينة</p>
</li>
<li>
<p>بيانات الاستخدام</p>
</li>
</ul>
<h4>بيانات الاستخدام</h4>
<p>يتم جمع بيانات الاستخدام تلقائيًا عند استخدام الخدمة.</p>
<p>قد تشمل بيانات الاستخدام معلومات مثل عنوان بروتوكول الإنترنت لجهازك (مثل عنوان IP)، نوع المتصفح، إصدار المتصفح، الصفحات التي تزورها على خدمتنا، وقت وتاريخ زيارتك، الوقت الذي تقضيه على تلك الصفحات، معرفات الجهاز الفريدة وبيانات تشخيصية أخرى.</p>
<p>عندما تصل إلى الخدمة عبر جهاز محمول، قد نقوم بجمع بعض المعلومات تلقائيًا، بما في ذلك، ولكن لا تقتصر على، نوع الجهاز المحمول الذي تستخدمه، معرف جهازك المحمول الفريد، عنوان IP لجهازك المحمول، نظام التشغيل المحمول لجهازك، نوع متصفح الإنترنت المحمول الذي تستخدمه، معرفات الجهاز الفريدة وبيانات تشخيصية أخرى.</p>
<p>قد نقوم أيضًا بجمع المعلومات التي يرسلها متصفحك كلما زرت خدمتنا أو عندما تصل إلى الخدمة عبر جهاز محمول.</p>
<h4>المعلومات من خدمات وسائل التواصل الاجتماعي من طرف ثالث</h4>
<p>تسمح لك الشركة بإنشاء حساب وتسجيل الدخول لاستخدام الخدمة عبر خدمات وسائل التواصل الاجتماعي من طرف ثالث التالية:</p>
<ul>
<li>جوجل</li>
<li>فيسبوك</li>
<li>إنستغرام</li>
<li>تويتر</li>
<li>لينكد إن</li>
</ul>
<p>إذا قررت التسجيل عبر أو منحنا الوصول إلى خدمة وسائل التواصل الاجتماعي من طرف ثالث، فقد نقوم بجمع بيانات شخصية مرتبطة بالفعل بحسابك على خدمة وسائل التواصل الاجتماعي من طرف ثالث، مثل اسمك، عنوان بريدك الإلكتروني، أنشطتك أو قائمة جهات الاتصال المرتبطة بذلك الحساب.</p>
<p>قد يكون لديك أيضًا خيار مشاركة معلومات إضافية مع الشركة عبر حسابك على خدمة وسائل التواصل الاجتماعي من طرف ثالث. إذا اخترت تقديم مثل هذه المعلومات والبيانات الشخصية، أثناء التسجيل أو بخلاف ذلك، فإنك تمنح الشركة إذنًا لاستخدامها ومشاركتها وتخزينها بطريقة تتماشى مع سياسة الخصوصية هذه.</p>
<h4>تتبع التكنولوجيا والكوكيز</h4>
<p>نستخدم الكوكيز والتقنيات المماثلة لتتبع النشاط على خدمتنا وتخزين بعض المعلومات. تشمل التقنيات المستخدمة البطاقات والعلامات والنصوص لجمع وتتبع المعلومات ولتحسين وتحليل خدمتنا. قد تشمل التقنيات التي نستخدمها:</p>
<ul>
<li><strong>الكوكيز أو كوكيز المتصفح.</strong> الكوكي هو ملف صغير يتم وضعه على جهازك. يمكنك توجيه متصفحك لرفض جميع الكوكيز أو للإشارة عندما يتم إرسال كوكي. ومع ذلك، إذا لم تقبل الكوكيز، قد لا تتمكن من استخدام بعض أجزاء خدمتنا. ما لم تقم بضبط إعدادات متصفحك لرفض الكوكيز، قد تستخدم خدمتنا الكوكيز.</li>
<li><strong>المنارات الإلكترونية.</strong> قد تحتوي بعض أقسام خدمتنا ورسائلنا الإلكترونية على ملفات إلكترونية صغيرة تعرف بالمنارات الإلكترونية (المعروفة أيضًا باسم GIFs الواضحة، علامات البكسل، وGIFs ذات البكسل الواحد) التي تسمح للشركة، على سبيل المثال، بحساب المستخدمين الذين زاروا تلك الصفحات أو فتحوا بريدًا إلكترونيًا ولإحصاءات مواقع الويب ذات الصلة (على سبيل المثال، تسجيل شعبية قسم معين والتحقق من سلامة النظام والخادم).</li>
</ul>
<p>يمكن أن تكون الكوكيز "دائمة" أو "جلسة". تبقى الكوكيز الدائمة على جهاز الكمبيوتر الشخصي أو الجهاز المحمول الخاص بك عندما تكون غير متصل بالإنترنت، بينما يتم حذف كوكيز الجلسة بمجرد إغلاق متصفحك.</p>
<p>نستخدم الكوكيز للجلسة ونعمل على الأغراض التالية:</p>
<ul>
<li>
<p><strong>الكوكيز الضرورية</strong> - نستخدم الكوكيز لتخزين المعلومات اللازمة لك لتسجيل الدخول.</p>
</li>
<li>
<p><strong>الكوكيز الوظيفية</strong> - نستخدم الكوكيز لتذكر المعلومات التي تغيرها أثناء استخدامك للخدمة.</p>
</li>
<li>
<p><strong>الكوكيز التحليلية</strong> - نستخدم الكوكيز لتحليل كيفية استخدام خدمتنا.</p>
</li>
</ul>
<h2>استخدام بياناتك الشخصية</h2>
<p>تستخدم الشركة البيانات الشخصية للأغراض التالية:</p>
<ul>
<li>
<p><strong>لتوفير وتحديث خدمتنا</strong> - نستخدم بياناتك الشخصية لتوفير وتحسين خدمتنا.</p>
</li>
<li>
<p><strong>لإدارة حسابك</strong> - لإدارة تسجيلك كمستخدم للخدمة. قد تستخدم بياناتك الشخصية لتزويدك بالمنتجات والخدمات التي طلبتها.</p>
</li>
<li>
<p><strong>للتحقق من هويتك</strong> - قد نستخدم بياناتك الشخصية للتحقق من هويتك.</p>
</li>
<li>
<p><strong>للتواصل معك</strong> - نستخدم بياناتك الشخصية للتواصل معك من خلال الرسائل الإدارية والخاصة المتعلقة بالخدمة.</p>
</li>
<li>
<p><strong>للتحليل والتقارير</strong> - نستخدم بياناتك لتحليل استخدام الخدمة وتقديم تقارير لتحسين أدائنا.</p>
</li>
<li>
<p><strong>لإرسال إشعارات</strong> - نستخدم بياناتك الشخصية لإرسال إشعارات لك، مثل التحديثات والعروض والعروض الترويجية.</p>
</li>
</ul>
<h2>نقل بياناتك الشخصية</h2>
<p>قد يتم نقل بياناتك إلى - وتخزينها - في أجهزة كمبيوتر تقع خارج ولايتك أو بلدك أو مقاطعتك أو بلدك القضائي الآخر حيث قد لا تكون قوانين حماية البيانات مشابهة لتلك التي في ولايتك القضائية.</p>
<p>إذا كنت موجودًا خارج عمان وقررت تقديم المعلومات لنا، يرجى ملاحظة أننا نقوم بنقل البيانات، بما في ذلك البيانات الشخصية، إلى عمان ومعالجتها هناك.</p>
<p>موافقتك على سياسة الخصوصية هذه متبوعة بتقديم مثل هذه المعلومات تمثل موافقتك على هذا النقل.</p>
<p>ستتخذ الشركة جميع الخطوات المعقولة لضمان معالجة بياناتك بشكل آمن ومتوافق مع سياسة الخصوصية هذه. لن يتم نقل بياناتك الشخصية إلى منظمة أو بلد إلا إذا كانت هناك ضوابط كافية في مكانها لضمان أمان بياناتك وأي بيانات شخصية أخرى.</p>
<h2>حماية بياناتك الشخصية</h2>
<p>أمن بياناتك الشخصية مهم لنا، ولكن تذكر أنه لا توجد طريقة إرسال عبر الإنترنت أو طريقة تخزين إلكتروني آمنة بنسبة 100%. بينما نسعى جاهدين لاستخدام وسائل مقبولة تجاريًا لحماية بياناتك الشخصية، لا يمكننا ضمان أمانها المطلق.</p>
<h2>حقوقك في البيانات الشخصية</h2>
<p>لديك حقوق معينة تتعلق ببياناتك الشخصية بموجب قوانين حماية البيانات المعمول بها. قد تشمل هذه الحقوق الحق في:</p>
<ul>
<li>
<p>طلب الوصول إلى بياناتك الشخصية، وتصحيح أي أخطاء فيها، أو طلب حذفها.</p>
</li>
<li>
<p>طلب تقييد معالجة بياناتك الشخصية أو الاعتراض عليها.</p>
</li>
<li>
<p>تلقي بياناتك الشخصية في شكل منظم وشائع الاستخدام وقابل للقراءة آليًا.</p>
</li>
<li>
<p>الانسحاب من أي موافقة قد تكون قد قدمتها لمعالجة بياناتك الشخصية.</p>
</li>
</ul>
<p>ل exercise any of these rights, please contact us using the contact details provided below.</p>
<h2>مقدمو الخدمة</h2>
<p>قد نستخدم شركات وأفرادًا من طرف ثالث لتسهيل خدمتنا ("مزودي الخدمة")، لتوفير الخدمة نيابة عنا، لأداء الخدمات المتعلقة بالخدمة، أو لمساعدتنا في تحليل كيفية استخدام خدمتنا.</p>
<p>قد يتم منح هؤلاء الأطراف الوصول إلى بياناتك الشخصية فقط لأغراض تنفيذ هذه المهام نيابة عنا وهم ملزمون بعدم الكشف عنها أو استخدامها لأي غرض آخر.</p>
<h2>الروابط إلى مواقع أخرى</h2>
<p>قد تحتوي خدمتنا على روابط إلى مواقع أخرى لا نملكها أو نتحكم فيها. إذا قمت بالنقر على رابط لطرف ثالث، فستتم توجيهك إلى موقع ذلك الطرف الثالث. نحن ننصحك بشدة بمراجعة سياسة الخصوصية لكل موقع تزوره.</p>
<p>ليس لدينا أي سيطرة على ولا نتحمل أي مسؤولية عن المحتوى أو سياسات الخصوصية أو الممارسات لأي مواقع أو خدمات تابعة لطرف ثالث.</p>
<h2>التغييرات في سياسة الخصوصية</h2>
<p>قد نقوم بتحديث سياسة الخصوصية هذه من وقت لآخر. سنقوم بإخطارك بأي تغييرات عن طريق نشر سياسة الخصوصية الجديدة على هذه الصفحة.</p>
<p>سندعك تعرف عبر البريد الإلكتروني و/أو عبر إشعار بارز على خدمتنا، قبل أن يصبح التغيير ساريًا ويحدث التحديث على تاريخ "آخر تحديث" في أعلى سياسة الخصوصية هذه.</p>
<p>يُنصح بمراجعة سياسة الخصوصية هذه بانتظام لأي تغييرات. تعتبر التغييرات التي تطرأ على سياسة الخصوصية هذه نافذة المفعول عند نشرها على هذه الصفحة.</p>
<h2>اتصل بنا</h2>
<p>إذا كان لديك أي أسئلة حول سياسة الخصوصية هذه، يرجى الاتصال بنا:</p>
<ul>
<li>
<p>عبر البريد الإلكتروني: <a href="mailto:info@tejwaly.com">info@tejwaly.com</a></p>
</li>
<li>
<p>بزيارة هذه الصفحة على موقعنا: <a href="https://portal.tejwaly.com/contact-us">https://portal.tejwaly.com/contact-us</a></p>
</li>
<li>
<p>عبر الهاتف: +968 1234 5678</p>
</li>
</ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
