import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import '../Css/CategoryItems.css'; // Ensure the path to your CSS file is correct
import { useNavigate } from 'react-router-dom';
import AdvCatComponent from '../Componnets/AdvCatComponent';
import SearchComponent from './SearchComponent';
const CategoryItems = () => {
  const [items, setItems] = useState([]);
  const [searchQuery, setSearchQuery] = useState(''); // State for search query
  const [category, setCategory] = useState(''); // State for selected category
  const [priceRange, setPriceRange] = useState([0, 1000]); // State for price range [min, max]
  const [ratingRange, setRatingRange] = useState([0, 5]); // State for rating range [min, max]
  const [color, setColor] = useState(''); // State for selected color
  const { id } = useParams();
  const navigate = useNavigate();

  const apiUrl = process.env.REACT_APP_API_URL;
  const baseImageUrl = `${apiUrl}/uploads/advimages/`;

  useEffect(() => {
    axios.get(`${apiUrl}/api/category_items/${id}`)
      .then(response => {
        setItems(response.data);
      })
      .catch(error => {
        console.error('There was an error fetching the items for the category:', error);
      });
  }, [id, apiUrl]);


  // Filter items based on search query and selected filters
  const getFilteredItems = () => {
    return items.filter(item =>
      (item.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.description.toLowerCase().includes(searchQuery.toLowerCase())) &&
      (category ? item.category === category : true) &&
      (priceRange[0] <= item.price && item.price <= priceRange[1]) &&
      (ratingRange[0] <= item.rating && item.rating <= ratingRange[1]) &&
      (color ? item.color === color : true)
    );
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleCategoryChange = (e) => {
    setCategory(e.target.value);
  };

  const handlePriceRangeChange = (e) => {
    const { name, value } = e.target;
    setPriceRange(prev => name === 'min' ? [value, prev[1]] : [prev[0], value]);
  };

  const handleRatingRangeChange = (e) => {
    const { name, value } = e.target;
    setRatingRange(prev => name === 'min' ? [value, prev[1]] : [prev[0], value]);
  };

  const handleColorChange = (e) => {
    setColor(e.target.value);
  };

 

 

  const handleGoBack = () => {
    navigate(-1); // Navigate back to the previous page
  };

  // Filter items based on search query
  const filteredItems = items.filter(item =>
    item.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
    item.description.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="container">
      <AdvCatComponent />
      
      <div className="">
      <div className="">
 <SearchComponent />
        </div>
        <div className="cards-column">
          {filteredItems.length > 0 ? (
            <>
              {filteredItems.map((item) => (
                <div className="card" key={item.ad_id}>
                  {item.imageurl && (
                    <img src={`${baseImageUrl}${item.imageurl}`} alt={item.title} className="card-image" />
                  )}
                  <h3 className="card-title">{item.title}</h3>
                  <p className="card-description">{item.description}</p>
                </div>
              ))}
            </>
          ) : (
            <h2 className="no-items-message">
              <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" className="bi bi-exclamation-circle" viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 0 1 .857-.576h.087c.471 0 .856.381.857.856v3.416a.856.856 0 0 1-1.713 0V4.995z" />
              </svg>
              لاتوجد سجلات في الوقت الحالي ... 
              <button onClick={handleGoBack} className="back-button">العودة</button>
            </h2>
          )}
        </div>
      </div>
    </div>
  );
};

export default CategoryItems;
